<div class="header__wrapper" [ngStyle]="{ 'background-color': headerSettings.showHeader ? '#23557b' : 'white' }">
  <div class="header__logo" *ngIf="!headerSettings.hideLogo">
    <img
      class="header__logo-img"
      *ngIf="headerSettings.customLogo; else defaultHeaderLogo"
      [src]="'assets/logo/' + headerSettings.customLogo"
      alt="e-solution-logo"
    />
    <ng-template #defaultHeaderLogo>
      <img class="header__logo-img" [src]="'assets/logo/' + theme.name + '-logo.png'" alt="e-solution-logo" />
    </ng-template>
  </div>
  <div class="header-navigation">
    <div class="header-navigation__buttons">
      <div
        *ngIf="!headerSettings.hideHomeButton"
        matRipple
        [matRippleColor]="matRippleColor"
        class="header-navigation__btn header-navigation__hide"
        (click)="navigateToHome()"
      >
        <img [src]="'assets/icons/home.svg'" alt="home" class="header-navigation__icon" />
      </div>
      <div
        *ngIf="!headerSettings.hideInfoButton"
        matRipple
        [matRippleColor]="matRippleColor"
        class="header-navigation__btn"
        (click)="openHelpPopup()"
      >
        <img [src]="'assets/icons/icn_info_w.png'" alt="info" class="header-navigation__icon" />
      </div>

      <div class="header-navigation__btn-text" *ngIf="headerSettings.hideLogoutButton === false">
        <div matRipple [matRippleColor]="matRippleColor" class="header-navigation__btn" (click)="navigateToHome()">
          <img [src]="'assets/icons/user.svg'" alt="logout" class="header-navigation__icon" />
        </div>
        <p [innerHTML]="'logout' | translate"></p>
      </div>

      <div
        *ngIf="!headerSettings.hideCancelButton"
        matRipple
        [matRippleColor]="matRippleColor"
        class="header-navigation__btn"
        (click)="navigateToHome()"
      >
        <img [src]="'assets/icons/close_white.png'" alt="info" class="header-navigation__icon" />
      </div>
      <div
        *ngIf="!headerSettings.hideLoginButton"
        matRipple
        [matRippleColor]="matRippleColor"
        class="header-navigation__btn"
        routerLink="/app/customer/login"
      >
        <img [src]="'assets/icons/user.svg'" alt="info" class="header-navigation__icon" />
      </div>
    </div>
  </div>
</div>
<div *ngIf="headerSettings.showStepper" class="stepper-progress">
  <div
    *ngFor="let step of stepperSettings?.steps; index as i; last as isLast"
    [class]="{
      'stepper-progress__step': true,
      'stepper-progress__step--completed': stepperSettings?.index > i,
      'stepper-progress__step--current': stepperSettings?.index === i,
      'stepper-progress__step--last': stepperSettings?.index === i && isLast
    }"
  ></div>
</div>
