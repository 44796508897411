export const environment = {
  environment: 'demo',
  project: 'E-Solution Terminal',
  production: true,
  hostUrl: '/api',
  hostUrlDolphin: '/api-dolphin',
  idNowApiUrl: 'https://go.test.idnow.de',
  octopusApiUrl: 'https://octopus-demo.staging.multilog24.de',
  matomoSiteId: '5',
  screenTimeout: {
    default: {
      timeOut: 60 * 10,
      notificationTime: 60 * 9
    },
    custom: {
      home: {
        timeOut: 60 * 5,
        notificationTime: 0
      },
      registration: {
        timeOut: 60 * 5,
        notificationTime: 60 * 5 - 15,
        notificationCountdown: 15,
        popupDialog: true,
        successScreenCountdown: 5
      }
    }
  }
};
