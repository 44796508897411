export const AUTO_IDENT_SONIO = {
  IFRAME_PROCESS_END: 'the proccess has ended',
  //IFRAME_PROCESS_END: 'session-ended',
  SESSION_STATUS_COMPLETED: 'COMPLETED',
  SESSION_REQUEST_REPEAT_COUNT: 10,
  SESSION_REQUEST_DELAY: 2000
};

export const PAYLADO_OFFER_NAME = 'paylado';

export const AGE_18_YEARS_IN_MS = 568025136000;

export const LAST_ONLINE_STATE_INTERVAL_IN_MS = 60 * 1000;

export const PRINTER_TIMEOUT = 10000;

export const POLARURL = {
  STAGING:
    'https://elice.staging.polar.multilog24.de/v1/prefill-loader/2354f501-e64b-49a2-9fb8-c3b333fd5719?tenantID=009afb84-a86e-4951-af38-4c2caabea276',
  DEMO: 'https://elice.demo.polar.multilog24.de/v1/prefill-loader/4c56be15-b6a4-4580-a30a-cc0a5985f896?tenantID=ad11535a-2ed5-4073-bcbc-0763fd51a66e',
  PROD: 'https://elice.polar.multilog24.de/v1/prefill-loader/9349f442-cfd3-4f79-8f05-9abcff479def?tenantID=1ca96305-af57-4e72-93c4-0c04c2432603'
};

export const DEMO_URL = 'https://xd.adobe.com/embed/744a3c4a-ca5f-4194-b890-9d27db6e5967-2119/';

export const POLAR_POST_MESSAGE = 'ePay receipt';

export const POLAR_BACK_TO_OFFERS_BUTTON_CLICKED_EVENT = 'uwPlpLoaded';

export const MERKUR_BETS = 'merkur bets';
export const MERKUR_SLOTS = 'merkur slots';

export const OFFER_REGISTRATION_SUCCESS = 'done';
export const OFFER_REGISTRATION_ERROR = 'rejected';
export const OFFER_REGISTRATION_PENDING = ['start-sent', 'started', 'final-sent', 'open', 'sent'];

export const GERMANY_COUNTRY_CODE = '49';

export const DEFAULT_SUCCESS_SCREEN_COUNTDOWN = 5;

export const VERIFICATION_STATUS_PASS = 'pass';
export const VERIFICATION_STATUS_PENDING = 'pending';
export const VERIFICATION_STATUS_FAIL = 'fail';
export const VERIFICATION_STATUS_NEEDS_REVIEW = 'needs_review';
