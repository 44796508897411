import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormInput } from '@app/shared/models/form-input';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService } from '@app/shared/services/helper.service';
import { getErrorMessage } from '@app/shared/helpers/validator';
import {
  checkPhoneNumberValid,
  validatePhoneNumberRegistration,
  validEmail
} from '@app/modules/registration/customValidators';
import { Subscription } from 'rxjs';
import { RegistrationService } from '@app/modules/registration/services/registration.service';
import { GERMANY_COUNTRY_CODE } from '@app/modules/registration/utils/constants';

@Component({
  selector: 'app-vs-phone-or-email',
  templateUrl: './vs-phone-or-email.component.html',
  styleUrls: ['./vs-phone-or-email.component.scss']
})
export class VsPhoneOrEmailComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() config: FormInput;
  @Input() registrationForm: FormGroup;
  @Input() isEmail: boolean;
  @Output() isEmailChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('phone_number_input') phone_number_input: ElementRef;
  showValidationLabels = false;
  selectedDialCode: { key: string; value: string; code?: string } = {
    key: 'de',
    code: GERMANY_COUNTRY_CODE,
    value: 'GERMANY'
  };
  phoneNumberForm: FormGroup;
  dialCodes: { key: string; value: string; code: string }[] = [
    {
      key: 'de',
      value: 'GERMANY',
      code: GERMANY_COUNTRY_CODE
    }
  ];

  phoneNumberChangesSub: Subscription = null;
  private validationInProgress = false;

  constructor(
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private registrationService: RegistrationService
  ) {
    this.phoneNumberForm = this.formBuilder.group({
      dialCode: new FormControl(GERMANY_COUNTRY_CODE, Validators.required),
      number: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    this.registrationForm?.setControl(this.config.key, this.phoneNumberForm);
    this.registrationService.dialCodeList.subscribe(dialCodes => {
      this.dialCodes = dialCodes;
    });

    this.phoneNumberForm.get('dialCode').valueChanges.subscribe(value => {
      this.selectedDialCode = this.dialCodes.find(item => item.code === value);
    });

    this.phoneNumberForm.get('number').valueChanges.subscribe(value => {
      if (!this.validationInProgress) {
        this.updateValidation(value);
      }
    });
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  onBlur(): void {
    if (this.config.validators) {
      this.showValidationLabels = true;
      this.phoneNumberForm.get('number').markAsTouched();
      this.phoneNumberForm.get('number').updateValueAndValidity();
    }
  }

  onFocusInOut(action: string, event: FocusEvent): void {
    const targetInput = event.target as HTMLInputElement;
    this.helperService.setCurrentInputValue(targetInput.value);

    if (this.config.validators) {
      this.showValidationLabels = action === 'in' || this.phoneNumberForm.get('number').value;
    }
    const dialCodeList = this.registrationService.dialCodeList$;
    if (!this.isEmail) {
      const splitedPhoneNumber = validatePhoneNumberRegistration(targetInput.value, dialCodeList);

      if (splitedPhoneNumber.phoneNumber) {
        this.phoneNumberForm.get('number').setValue(splitedPhoneNumber.phoneNumber);
      }
      if (splitedPhoneNumber.dialCode) {
        this.phoneNumberForm.get('dialCode').setValue(splitedPhoneNumber.dialCode);
      }
    }

    this.helperService.setKeyboardInputConfig(this.config);
  }

  updateValidation(value: string): void {
    this.validationInProgress = true;

    const detectedEmail = /[^0-9+]/.test(value);

    if (this.isEmail !== detectedEmail) {
      this.isEmail = detectedEmail;
      this.isEmailChange.emit(this.isEmail);
    }

    if (this.isEmail) {
      this.phoneNumberForm.get('number').setValidators([Validators.required, validEmail()]);
      this.phoneNumberForm.get('dialCode').disable();
    } else {
      this.phoneNumberForm
        .get('number')
        .setValidators([Validators.required, Validators.compose([checkPhoneNumberValid()])]);
    }

    this.phoneNumberForm.get('number').updateValueAndValidity();
    this.validationInProgress = false;
  }

  resetInput(): void {
    this.helperService.clearKeyboardInput(true, this.config.key);
    this.phoneNumberForm.get('number').reset();
    this.phoneNumberForm.get('dialCode').enable();
    this.isEmail = false;
    this.isEmailChange.emit(this.isEmail);
  }

  focusInput(): void {
    this.phone_number_input?.nativeElement?.focus();
  }

  getErrorMessage(error: string): string {
    return getErrorMessage(error);
  }

  ngOnDestroy(): void {
    if (this.phoneNumberChangesSub) this.phoneNumberChangesSub.unsubscribe();
  }
}
