import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StepperSettings } from '@app/shared/models/stepper-settings';
import { HeaderSettings } from '@app/shared/models/header-settings';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  ShowCancelRegMessage = new Subject<boolean>();
  ShowCancelOfferMessage = new Subject<boolean>();
  formStepperSettings = new Subject<StepperSettings>();
  headerSettings = new Subject<HeaderSettings>();
  HelpTextTag = new Subject<string>();

  /**
   * set show cancel Registration message setting
   *
   * @params {setting: boolean} Enable/disable the display of the cancel registration message into a popup
   */
  setShowCancelRegMessage(setting: boolean): void {
    this.ShowCancelRegMessage.next(setting);
  }

  /**
   * set show cancel Offers message setting
   *
   * @params {setting: boolean} Enable/disable the display of the cancel Offers message into a popup
   */
  setShowCancelOffersMessage(setting: boolean): void {
    this.ShowCancelOfferMessage.next(setting);
  }

  /**
   * set the stepper settings
   *
   * @params settings: {index: number, selected: string, steps: any}
   * {index: number} Current active step number
   * {selected: string} Current active step label
   * {steps: any} All the steps of the stepper
   */
  setStepperSettings(stepperSettings: StepperSettings): void {
    this.formStepperSettings.next(stepperSettings);
  }

  /**
   * set the header settings
   *
   * @params settings: {showHeader: boolean, showStepper: boolean, logo: string}
   * {showHeader: boolean} Enabled/disable the display of the header's background blue color
   * {showStepper: boolean} Enable/disable the display of the stepper into the header
   * {logo: string} Specify which logo should be displayed on the header (possible values: merkur, paylado)
   * {text: string} text to display in the middle of the header
   */
  setHeaderSettings(headerSettings: HeaderSettings): void {
    this.headerSettings.next(headerSettings);
  }

  setHelpText(TranslationTag: string): void {
    this.HelpTextTag.next(TranslationTag);
  }
}
