<div class="auto-ident__tac-iframe-wrapp">
  <div class="loading"></div>
  <iframe
    [src]="iframeSrcUrl | safeUrl"
    allow="camera; microphone"
    class="auto-ident__tac-iframe"
    [ngStyle]="{ display: isIframeLoaded ? 'block' : 'none' }"
    sandbox="allow-forms allow-popups allow-pointer-lock allow-same-origin allow-scripts"
  >
  </iframe>
</div>
