import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { generateRandomStrongPassword } from '@app/shared/helpers/common';
import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-password-suggestion-card',
  templateUrl: './password-suggestion-card.component.html',
  styleUrls: ['./password-suggestion-card.component.scss']
})
export class PasswordSuggestionCardComponent implements OnInit {
  @Output() public passwordSelection: EventEmitter<string> = new EventEmitter<string>();
  @Input() refreshText = __('refresh_password_text');
  @Input() selectPasswordMessage = __('select_suggested_password_message');
  @Input() printInfo = __('password_print_info');
  randomPassword: string;

  ngOnInit(): void {
    this.refreshPassword();
  }

  sendRandomGeneratedPassword(): void {
    this.passwordSelection.emit(this.randomPassword);
  }
  refreshPassword(): void {
    const randomLength = Math.floor(Math.random() * (14 - 10 + 1) + 10);
    this.randomPassword = generateRandomStrongPassword(randomLength);
  }
}
