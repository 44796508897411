import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageReloadService } from '@app/shared/services/page-reload.service';
import { MatDialog } from '@angular/material/dialog';
import { HeaderService } from '@app/shared/services/header.service';
import { PopupService } from '@app/shared/services/popup.service';
import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';
import { HeaderSettings } from '@app/shared/models/header-settings';
import { Popup } from '@app/shared/models/popup';
import { StepperSettings } from '@app/shared/models/stepper-settings';
import { Theme } from '@themes/models/theme';
import { ThemeService } from '@app/modules/layout/services/theme.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private allSubscriptions: Subscription[] = [];
  matRippleColor = 'rgb(255,255,255,30%)';
  ShowCancelRegMessage = false;
  ShowCancelOfferMessage = false;
  stepperSettings: StepperSettings;
  helpTextTranslationTag = 'empty';

  // header settings
  headerSettings: HeaderSettings = {
    showHeader: true,
    showStepper: true,
    hideHomeButton: false,
    hideLogo: false,
    text: '',
    hideCancelButton: false,
    hideLogoutButton: true,
  };

  // cancellation popup
  cancelRegistrationPopupSetting: Popup = {
    data: {
      buttons: {
        close: __('back_to_registration'),
        action: __('ok')
      },
      cssClass: 'popup__cancel-text',
      content: __('cancellation_message'),
      withTranslation: true
    },
    width: '50%',
    height: '50%',
    panelClass: 'popup',
    autoFocus: false
  };
  // cancellation popup
  cancelOffersSetting: Popup = {
    data: {
      buttons: {
        close: __('back_to_offers'),
        action: __('ok')
      },
      cssClass: 'popup__cancel-text',
      content: __('cancellation_offers'),
      withTranslation: true
    },
    width: '50%',
    height: '50%',
    panelClass: 'popup',
    autoFocus: false
  };

  // help popup
  helpPopupSetting: Popup = {
    data: {
      cssClass: 'popup__help-text',
      content: 'empty',
      withTranslation: true
    },
    width: '50%',
    height: '50%',
    panelClass: 'popup',
    autoFocus: false
  };

  theme: Theme;

  constructor(
    private pageReload: PageReloadService,
    public dialog: MatDialog,
    private headerService: HeaderService,
    private popupService: PopupService,
    private themeService: ThemeService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.themeService.getTheme().subscribe(theme => (this.theme = theme));

    const showCancelRegMessageSub = this.headerService.ShowCancelRegMessage.subscribe(value => {
      this.ShowCancelRegMessage = value;
    });
    this.allSubscriptions.push(showCancelRegMessageSub);

    const showCancelOffersSub = this.headerService.ShowCancelOfferMessage.subscribe(value => {
      this.ShowCancelOfferMessage = value;
    });
    this.allSubscriptions.push(showCancelOffersSub);

    const headerSettingsSub = this.headerService.headerSettings.subscribe(value => {
      this.headerSettings = value;
    });
    this.allSubscriptions.push(headerSettingsSub);

    const formStepperSub = this.headerService.formStepperSettings.subscribe(value => {
      this.stepperSettings = value;
    });
    this.allSubscriptions.push(formStepperSub);

    const helpTextTagSub = this.headerService.HelpTextTag.subscribe(value => {
      this.helpPopupSetting.data.content = value;
    });
    this.allSubscriptions.push(helpTextTagSub);
  }

  /**
   * Navigate user to the main menu
   *
   * @remarks
   * Show confirmation message popup, if the header settings enable it.
   */
  navigateToHome(): void {
    if (this.ShowCancelRegMessage) {
      this.popupService.openDialog(this.cancelRegistrationPopupSetting);
    } else if (this.ShowCancelOfferMessage) {
      this.popupService.openDialog(this.cancelOffersSetting);
    } else {
      this.pageReload.redirectToHome();
    }
  }

  navigateToProfile(): void {
    this.router.navigate(['/app/customer/profile']);
  }

  openHelpPopup(): void {
    this.popupService.openDialog(this.helpPopupSetting);
  }

  ngOnDestroy(): void {
    for (const subscription of this.allSubscriptions) {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    }
  }
}
