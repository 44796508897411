export const infoTestLabelText = {
  de: 'Vielen Dank für die Nutzung unseres Angebotes! Wir befinden uns derzeit im Live-Test-Betrieb und bitten daher um Verständnis, sollten evtl. Verzögerungen oder Probleme bei dieser neuartigen Registrierungsform auftreten.',
  en: 'Thank you for using our offer! We are currently in live test mode and therefore ask for your understanding if there should be any delays or problems with this new form of registration.',
  tr: 'Teklifimizi kullandığınız için teşekkür ederiz! Şu anda canlı test modundayız ve bu nedenle, bu yeni kayıt şekliyle ilgili herhangi bir gecikme veya sorun olması durumunda anlayışınızı rica ediyoruz.',
  es: '¡Gracias por utilizar nuestra oferta! Actualmente estamos en modo de prueba en vivo y, por lo tanto, le pedimos que comprenda si debe haber demoras o problemas con esta nueva forma de registro.'
};

export const sentryData = {
  CUSTOM_ERROR_MESSAGE: {
    UPDATE_CUSTOMER_ERROR: 'Custom message: updateCustomer API point has failed',
    GET_CUSTOMER_INFO: 'Custom message: customerInfo API point has failed',
    FINISH_AUTO_IDENT_ERROR: 'Custom message: finishAutoIdent API point has failed',
    OTP_LOGIN_ERROR: 'Custom message: otpLogin API point has failed',
    OTP_CONFIRM_ERROR: 'Custom message: otpConfirm API point has failed',
    OTP_RESEND_ERROR: 'Custom message: otpResend API point has failed',
    TERMINAL_ERROR: 'Custom message: terminal API point has failed',
    GET_AVAILABLE_CREDIT_ERROR: 'Custom message: getAvailableCredit API point has failed',
    GET_QR_CODE_DATA_ERROR: 'Custom message: payladoRequestQrCode API point has failed',
    GET_TRANSACTION_STATUS_ERROR: 'Custom message: payladoRetrieveStatus API point has failed',
    EXCECUTE_TRANSACTION_ERROR: 'Custom message: payladoExecuteTransaction API point has failed',
    ACTIVATE_CASH_ACCEPTOR_ERROR: 'Custom message: activateCashAcceptor API point has failed',
    INCREASE_CREDIT_ERROR: 'Custom message: increaseCashCredit API point has failed',
    START_PAYOUT_ERROR: 'Custom message: startPayout API point has failed',
    GET_CASHOUT_PROGRESS_ERROR: 'Custom message: getCashoutProgressError API point has failed',
    PRINT_DATA_ERROR: 'Custom message: print API point has failed',
    GET_USER_OFFERS_ERROR: 'Custom message: getting user offers API point has failed'
  }
};
