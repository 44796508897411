import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { FormInput, FormInputOptions } from '@app/shared/models/form-input';
import { AutoIdentEventEmitter, StepThreeUserData } from '../../models/auto-ident-data';
import { RegistrationForm } from '@app/modules/registration/types/registration-form';
import { NotificationService } from '@app/shared/services/notification.service';
import { AutoIdentService } from '../../services/auto-ident.service';
import { Subscription } from 'rxjs';
import { InitVerificationResponse } from '../../types/auto-ident-type';
import { TranslationService } from '@app/shared/services/translation.service';
import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';
import { AUTO_IDENT_SONIO } from '../../utils/constants';
import {
  ADDRESSKEY,
  CITIZENSHIPKEY,
  COUNTRYOFBIRTHKEY,
  FIRSTNAMEKEY,
  GENDERKEY,
  OCCUPATIONKEY,
  VERIFIEDPHONENUMBERKEY
} from '../../registration.model';
import { HeaderSettings } from '@app/shared/models/header-settings';
import { HeaderService } from '@app/shared/services/header.service';
import { TerminalDataService } from '@app/shared/services/terminal-data.service';
import { RegistrationService } from '../../services/registration.service';

@Component({
  selector: 'app-auto-ident-iframe',
  templateUrl: './auto-ident-iframe.component.html',
  styleUrls: ['./auto-ident-iframe.component.scss']
})
export class AutoIdentIframeComponent implements OnInit, OnDestroy {
  @Output() submitAutoIdentEventEmitter: EventEmitter<AutoIdentEventEmitter> =
    new EventEmitter<AutoIdentEventEmitter>();
  @Input() registrationForm: FormGroup;
  @Input() registrationFormFields: RegistrationForm;
  public multilogLogo: HTMLElement;
  public iframeSrcUrl: string = '';
  public isIframeLoaded: boolean = false;
  private autoIdentInitalSubscriber: Subscription = null;
  private autoIdentSessionSubscriber: Subscription = null;
  private detectGenderSubscriber: Subscription = null;
  public genderReadFromDocument: boolean = true;
  private initAutoVerificationData: InitVerificationResponse = {
    iframeUrl: '',
    sessionId: ''
  };

  constructor(
    private _notificationService: NotificationService,
    private _translationService: TranslationService,
    private _autoIdentService: AutoIdentService,
    private headerService: HeaderService,
    private terminalDataService: TerminalDataService,
    private registrationService: RegistrationService
  ) {}

  ngOnInit(): void {
    this.startAutoIdentification();
    this.multilogLogo = document.getElementById('logo-multilog');
    this.multilogLogo.style.visibility = 'hidden';
    // this.checkHeaderSettings(); white background for demo
    this.headerService.setHelpText(__('help_text_auto_ident'));
  }

  checkHeaderSettings(): void {
    const terminalData = this.terminalDataService.getTerminalData();
    if (terminalData['environment'] === 'demo') {
      const headerSettings: HeaderSettings = {
        showHeader: false,
        hideLogo: true
      };
      this.headerService.setHeaderSettings(headerSettings);
    }
  }

  private startAutoIdentification(): void {
    const lang = this._translationService.getCurrentLanguage();
    const payload = {
      language: lang
    };
    this.autoIdentInitalSubscriber = this._autoIdentService.initAutoVerification(payload).subscribe(
      response => {
        if (response && response.iframeUrl) {
          this.initAutoVerificationData = response;
          this.iframeSrcUrl = response.iframeUrl;
          this.isIframeLoaded = true;
        }
      },
      () => {
        this.isIframeLoaded = false;
        const errorMessage = __('init_auto_identification_error_msg');
        this._notificationService.showMessage(errorMessage, 'error', false, 3000);
        const autoIdentEventEmitter: AutoIdentEventEmitter = {
          isSuccess: false,
          sessionID: ''
        };
        this.submitAutoIdentEventEmitter.emit(autoIdentEventEmitter);
      }
    );
  }

  @HostListener('window:message', ['$event'])
  iframeMessageConnection(event: MessageEvent<any>) {
    const { IFRAME_PROCESS_END } = AUTO_IDENT_SONIO;
    if (event && event.data === IFRAME_PROCESS_END && this.isIframeLoaded) {
      this.getAutoIdentData();
      this.isIframeLoaded = false;
    }
  }

  getAutoIdentData(): void {
    const sessionID = this.initAutoVerificationData.sessionId;
    this.autoIdentSessionSubscriber = this._autoIdentService.getSessionResult(sessionID).subscribe(
      response => {
        const { SESSION_STATUS_COMPLETED } = AUTO_IDENT_SONIO;
        if (response && response.status === SESSION_STATUS_COMPLETED) {
          const userData: StepThreeUserData = this._autoIdentService.formatResultData(response);
          this.detectGender(userData);
        } else {
          const errorMessage = __('get_session_result_auto_identification_error_msg');
          this._notificationService.showMessage(errorMessage, 'error', false, 3000);
          const autoIdentEventEmitter: AutoIdentEventEmitter = {
            isSuccess: false,
            sessionID: ''
          };
          this.submitAutoIdentEventEmitter.emit(autoIdentEventEmitter);
        }
      },
      () => {
        const errorMessage = __('get_session_result_auto_identification_error_msg');
        this._notificationService.showMessage(errorMessage, 'error', false, 3000);
        const autoIdentEventEmitter: AutoIdentEventEmitter = {
          isSuccess: false,
          sessionID: ''
        };
        this.submitAutoIdentEventEmitter.emit(autoIdentEventEmitter);
      }
    );
  }

  detectGender(userData: StepThreeUserData): void {
    if ((!Object.prototype.hasOwnProperty.call(userData, GENDERKEY) || !userData.gender) && userData.first_name) {
      this.genderReadFromDocument = false;
      const payload = {
        first_name: userData.first_name
      };
      this.detectGenderSubscriber = this.registrationService.detectGender(payload).subscribe(res => {
        userData.gender = res.gender;
        this.prefilstepThreeForm(userData);
      });
    } else {
      this.prefilstepThreeForm(userData);
    }
  }

  prefilstepThreeForm(data: StepThreeUserData): void {
    if (!data) return;
    const filteredData = this.removeEmpty(data);
    const { stepThree } = this.registrationFormFields;
    this.registrationForm.patchValue(filteredData);

    const fieldsToLock = stepThree.filter(filed => !this.excludedFieldsFromLocking.includes(filed.key));
    fieldsToLock.map((formField: FormInput) => {
      const { key, childFields } = formField;
      switch (key) {
        case CITIZENSHIPKEY:
          const citizenshipConfig: FormInputOptions[] = this.citizenshipConfig?.options || [];
          this.lockSelectFieldIfValid(key, citizenshipConfig);
          break;

        case GENDERKEY:
          const defaultGender = 'male';
          const genderConfig: FormInputOptions[] = this.genderConfig?.options || [];

          if (this.genderReadFromDocument) {
            this.lockSelectFieldIfValid(key, genderConfig);
          }

          if (data && data.gender === '' && this.hasValueInArray(defaultGender, genderConfig)) {
            this.setDefaultValueIfEmpty(key, defaultGender);
          }
          break;

        case COUNTRYOFBIRTHKEY:
          const defaultContryOfBirth = 'DEU';
          const countryOfBirth: FormInputOptions[] = this.countryOfBirthConfig?.options || [];

          this.lockSelectFieldIfValid(key, countryOfBirth);

          if (data && data.country_of_birth === '' && this.hasValueInArray(defaultContryOfBirth, countryOfBirth)) {
            this.setDefaultValueIfEmpty(key, defaultContryOfBirth);
          }
          break;

        case ADDRESSKEY:
          this.lockAddressFieldIfValid(key, childFields);
          break;

        case OCCUPATIONKEY:
          const defaultOccupation = 'employee';
          const occupationConfig: FormInputOptions[] = this.occupationConfig?.options || [];

          if (data && data.occupation === '' && this.hasValueInArray(defaultOccupation, occupationConfig)) {
            this.setDefaultValueIfEmpty(key, defaultOccupation);
          }
          break;

        default:
          this.lockInputFieldIfValid(key);
          break;
      }
    });

    const autoIdentEventEmitter: AutoIdentEventEmitter = {
      isSuccess: true,
      sessionID: this.initAutoVerificationData.sessionId
    };
    this.submitAutoIdentEventEmitter.emit(autoIdentEventEmitter);
    this.multilogLogo.style.visibility = 'visible';
  }

  removeEmpty(obj) {
    return Object.entries(obj)
      .filter(([_, v]) => !!v)
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? this.removeEmpty(v) : v }), {});
  }

  get citizenshipConfig(): FormInput {
    return this.registrationFormFields.stepThree.find(field => field.key === CITIZENSHIPKEY);
  }

  get genderConfig(): FormInput {
    return this.registrationFormFields.stepThree.find(field => field.key === GENDERKEY);
  }

  get countryOfBirthConfig(): FormInput {
    return this.registrationFormFields.stepThree.find(field => field.key === COUNTRYOFBIRTHKEY);
  }

  get occupationConfig(): FormInput {
    return this.registrationFormFields.stepThree.find(field => field.key === OCCUPATIONKEY);
  }

  get excludedFieldsFromLocking(): Array<string> {
    return [VERIFIEDPHONENUMBERKEY];
  }

  lockInputFieldIfValid(key: string): void {
    const formInput: AbstractControl = this.registrationForm.get(key);
    const value = formInput && formInput.value ? formInput.value : null;
    const isValid = formInput && formInput.valid ? formInput.valid : false;

    if (value && isValid) {
      this.registrationForm.controls[key].disable();
    }
  }

  lockSelectFieldIfValid(key: string, options: FormInputOptions[]): void {
    const formInput: AbstractControl = this.registrationForm.get(key);
    const value = formInput && formInput.value ? formInput.value : null;

    if (this.hasValueInArray(value, options)) {
      this.registrationForm.controls[key].disable();
      return;
    }
    this.registrationForm.get(key).setValue('');
  }

  hasValueInArray(value: string, options: FormInputOptions[]): boolean {
    if (!options || !Array.isArray(options)) return false;

    const hasValue = options.some(element => {
      if (element && element.key === value) return true;
      return false;
    });
    return hasValue;
  }

  lockAddressFieldIfValid(parentKey: string, childFields: FormInput[]): void {
    const parentFormInput: AbstractControl = this.registrationForm.get(parentKey);
    if (parentFormInput && childFields && Array.isArray(childFields)) {
      childFields.map(childField => {
        const { key } = childField;
        const childFormInput: AbstractControl = parentFormInput.get(key);
        const childValue = childFormInput && childFormInput.value ? childFormInput.value : null;
        const isChildValueValid = childFormInput && childFormInput.valid ? childFormInput.valid : false;

        if (childValue && isChildValueValid) {
          this.registrationForm.controls[parentKey]['controls'][key].disable();
        }
      });
    }
  }

  setDefaultValueIfEmpty(key: string, value: string): void {
    if (this.registrationForm?.get(key)?.value === '') {
      this.registrationForm.get(key).setValue(value);
    }
  }

  ngOnDestroy(): void {
    if (this.autoIdentInitalSubscriber) this.autoIdentInitalSubscriber.unsubscribe();
    if (this.autoIdentSessionSubscriber) this.autoIdentSessionSubscriber.unsubscribe();
    if (this.detectGenderSubscriber) this.detectGenderSubscriber.unsubscribe();
  }
}
