import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProjectConfigService } from '@app/shared/services/project-config.service';
import { ReplaySubject, Subject } from 'rxjs';
import { FormInput } from '@app/shared/models/form-input';
import { AbstractControl } from '@angular/forms';
import QRCode from 'qrcode';
import { InputData } from '../models/input-data';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  environment: any;
  inputKeyboardConfig = new Subject<any>();
  clearInput = new Subject<{ clearInput: boolean; inputName?: string }>();
  depositLimitValue = new Subject<boolean>();
  URL_PARAM_NAME = 'token';
  showVirtualKeyboard = new Subject<boolean>();
  temporaryInputValue: string;
  temporaryInputIndex = new Subject<InputData>();
  retryRequestClicked = new Subject<boolean>();
  keyPressed = new Subject<string>();
  payinInterruptor = new Subject<void>();
  ecommerceClicked = new ReplaySubject<boolean>();

  constructor(private http: HttpClient, private config: ProjectConfigService) {
    this.environment = this.config.getConfig();
  }

  setKeyboardInputConfig(config: any): void {
    this.inputKeyboardConfig.next(config);
  }

  /**
   * Clear the keyboard’s input.
   * @param {boolean} [clearInput] required - For default input
   * @param {string} [inputName] optional - For specific input, the internal input to select
   */
  clearKeyboardInput(clearInput: boolean, inputName?: string): void {
    this.clearInput.next({ clearInput, inputName });
  }

  showKeyboard(value: boolean): void {
    this.showVirtualKeyboard.next(value);
  }

  updateDepositLimitValue(value: any, formControl: AbstractControl, fieldConfig: FormInput): void {
    const maxValue = fieldConfig.validators?.maxValue;
    const minValue = fieldConfig.validators?.minValue;

    const newValue = value > maxValue ? maxValue : value < minValue ? minValue : value;
    formControl.setValue(newValue);
    this.depositLimitValue.next(newValue);
  }

  removeEmptyProperties<T>(obj: T): T {
    Object.keys(obj).forEach(propName => {
      if (!obj[propName]) {
        delete obj[propName];
      }
    });
    return obj;
  }

  getEmailTokenFromUrl(): string {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(this.URL_PARAM_NAME);
  }

  //    #todo [goce mitkovski]: not sure if this will work update if needed
  generateQrCode(qrCodeUri: string, margin: number, width: number): any {
    return QRCode.toCanvas(qrCodeUri, {
      margin,
      width
    });
  }

  getCurrentInputValue(): string {
    return this.temporaryInputValue;
  }

  setKeyPressedValue(value: string) {
    this.keyPressed.next(value);
  }

  setCurrentInputValue(value: string): void {
    this.temporaryInputValue = value;
  }

  setCurrentInputIndex(inputIndex: number = 0, inputName: string): void {
    this.temporaryInputIndex.next({ index: inputIndex, name: inputName });
  }

  interruptPayin(): void {
    this.payinInterruptor.next();
  }

  getInterruptObservable() {
    return this.payinInterruptor.asObservable();
  }

  goToEcommerceAfterLogin(clicked: boolean): void {
    this.ecommerceClicked.next(clicked);
  }
}
